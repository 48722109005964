body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face{
  font-family:"FontAwesome";
  font-display: swap;
  src:url("https://use.fontawesome.com/releases/v5.6.3/css/all.css");
}

fa {
  font-family: 'FontAwesome', arial, helvetica, sans-serif;
} 

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar-brand {
  font-size: 15px;
}

#main-header {
  font-family: 'Girassol cursive';
  font-size: 60px;
  color: white;
  font-weight: 500;
  letter-spacing: 2px;
  text-align: center;
  position: absolute;
  top: 15%;
  /* top: 35%; */
  /* left: 25%; */
  z-index: 1;
}

#main-header img{
width: 35%;
}

.img-text {
  display: none;
}

.gal-text {
  /* display: none; */
  /* width: 300px; */
}

.fixed-top {
  padding: 20px;
  /* opacity: 0.4; */
}

.navbar-brand {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.nav-link {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: white !important;
}

.nav-link:hover {
  border-bottom: 1px solid white;
 }

#navbarSupportedContent {
  justify-content: flex-end;
}

#logo {
  width: 10%;
}

#newAbout {
/* border: 1px solid red; */
 margin-top: 50px;
 margin-bottom: 50px;
}

#newAbout h2{
  text-align: center;
  font-family: 'Girassol cursive';
  font-size: 50px;
  letter-spacing: 2px;
}


#newAbout h3{
  text-align: center;
  font-family: 'Girassol cursive';
  font-size: 20px;
  letter-spacing: 2px;
}



#newAbout p{
  line-height: 1.8em;
  text-align: center;
  font-family: 'Girassol cursive';
  letter-spacing: 2px;
}

#new-home-contact {
  padding-top: 30px;
  padding-bottom: 30px;
}

#new-home-contact p {
  text-align: center;
  font-family: 'Girassol cursive';
  /* font-size: 50px; */
  letter-spacing: 2px;
}

#new-home-contact h3{
  text-align: center;
  font-family:  'Girassol cursive';
  font-size: 40px;
  letter-spacing: 2px;
  color: #fff;
}


#the-process {
  /* border: 2px solid red; */
  margin-top: 78px;
  padding-left: 0px;
  padding-right: 0px;
}

#the-process #advantage {
  padding-bottom: 50px;
}

#the-process #how-we-work {
  padding-bottom: 50px;
}

#the-process h1{
  text-align: center;
  padding-top: 50px;
  /* padding-bottom: 50px; */
  /* padding-left: 50px; */
  font-family: 'Girassol cursive';
  font-size: 40px;
  letter-spacing: 2px;
  font-weight: 800;
  text-transform: uppercase;
  /* text-align: left; */
}

#the-process hr {
  width: 70%;
  background-color: #fff;
}

#the-process h3{
  text-align: center;
  /* padding-top: 50px; */
  /* padding-left: 60px; */
  font-family: 'Girassol cursive';
  font-size: 30px;
  letter-spacing: 2px;
  font-weight: 800;
  text-transform: uppercase;
}

#the-process p {
  text-align: center;
  /* padding-top: 50px; */
  /* padding-left: 60px; */
  padding: 0px 38px;
  font-family: 'Girassol cursive';
  font-size: 18px;
  letter-spacing: 2px;
}

#the-process ul {
  list-style: none;
  padding: 25px;
}

#the-process ul li {
  padding-top: 10px;
}

#the-process #how-we-work {
  background-color: #343a40;
  width: 100%;  
  color: #fff;
}

#the-process #how-we-work p {
  text-align: center;
  font-family: 'Girassol cursive';
  /* font-size: 50px; */
  letter-spacing: 2px;
}

#projects {
  margin-top: 30px;
  padding-top: 70px;
  padding-bottom: 70px;
}

#projects h1 {
  text-align: center;
  font-family: 'Girassol cursive';
  font-size: 55px;
  letter-spacing: 2px;
}

#modal-container {
  /* border: 1px solid green; */
  padding: 50px;
}

#modal-container h2{
  text-align: center;
  font-family: 'Girassol cursive';
  font-size: 50px;
  letter-spacing: 2px;
}

#modal-container input {
  margin-top: 10px;
  margin-bottom: 10px;
}

#close-modal{
  border-radius: 20px;
  float: right;
  /* margin: 20px; */
  background-color: #343a40;
  border-color: #343a40;
}

#contact-btn {
  /* text-align: center; */
  text-transform: uppercase;
  padding-top: 8px;
  margin-bottom: 0px;
  font-size: 14px;
  /* margin-top: 7px; */
  color: #fff;
  cursor: pointer;
}

#contact-btn:hover {
border-bottom: 1px solid white;
}

.ReactModal__Overlay--after-open {
  z-index: 4 !important;
}

.ReactModal__Content {
  z-index: 3 !important;
}

.inline-errormsg {
  color: red;
  font-size: 12px;
}

.hidden {
  display: none;
}
/* 
#img-gal:hover~.gal-text {
  display: block;
} */

/* #img-gal:hover, #img-gal:focus, #img-gal:active {
  border: 3px solid red !important ;
}  */

/* big tablets to 1200px: widths smaller than the 1140px row */
@media only screen and (max-width: 1200px) {}
/* @media all and (width: 768px) and (height: 1024px) and (orientation:portrait){
  .landing {
    height: 1000px;
  }
}

@media all and (height: 800px) and (orientation:landscape) {
  .landing {
    height: 1000px;
  }
} */

/* small tablet to big tablets in portrait/landscape mode: from 768px to 1023px */
@media only screen and (max-width: 1023px) {
  
}


/* small phones to small tablets: from 481 to 767 pixels */
@media only screen and (max-width: 830px) {
  .img-text {
    display: block;
    text-align: center;
  }

  .navbar-brand {
    font-size: 12px;
  }
  /* #img-gal:hover, #img-gal:active, #img-gal:focus {

  } */
  /* .hvr-glow:hover, .hvr-glow:active, .hvr-glow:focus {

  } */
  #main-header {
    top: 20%;
    /* left: 13%; */
  }
  #main-header img {
    width: 50% ;
  }

  #newAbout {
    /* border: 1px solid red; */
     margin-top: 30px;
     margin-bottom: 30px;
    }

  #newAbout h3{
    font-size: 15px;
  }

  #newAbout h2{
    font-size: 30px;
  }

  #new-home-contact h3{
    font-size: 28px;
  }


#the-process h1{
  /* text-align: center; */
  padding-top: 50px;
  font-size: 30px;

}

#the-process hr {
  background-color: #fff;
}

#the-process h3{
  font-size: 25px;
}

#the-process p {
  font-size: 15px;
  padding: 0px 20px;
} 

#modal-container {
  padding: 10px;
  padding-top: 60px;
  /* margin: 20px; */
  /* padding: 50px; */
  /* width: 100%; */
}

#modal-container h2{
  font-size: 20px;
}

#modal-container .btn-danger {
  margin: 0px;
  padding: 3px 6px;
}

#modal-container input {
  margin-top: 5px;
  margin-bottom: 5px;
}


.ReactModal__Content {
  margin: 0px !important;
  /* padding: 0px 10px; */
  margin-top: 80px !important;
  padding: 20px 10px !important;
}

#icon-row {
  padding-top: 80px;
}

}


/* small pnhones from 0 to 480px */
@media only screen and (max-width: 480px) {
 .img-text {
   display: block;
   text-align: center;
 }

 .carrosal {
   height: 70vh !important;
 }

 #main-header {
   top: 20%;
   left: 5%;
   font-size: 50px;;
 }
 #main-header img {
  width: 50% ;
}


#the-process h1{
  /* text-align: center; */
  padding-top: 50px;
  font-size: 20px;
}

#the-process h3{
  font-size: 18px;
}
#the-process p {
  font-size: 15px;
  padding: 0px 10px;
} 
}